import React, { useEffect, useState } from 'react';
import { Box, Button, HStack, Text, IconButton, VStack } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import SnippetCard from './SnippetCard';
import FeaturedChannels from './FeaturedChannels';
import { API_BASE_URL } from '../config';

function SnippetList({ searchQuery, timeFilter, selectedCategories, filterSnippets, onOpenLoginModal }) {
  const [allSnippets, setAllSnippets] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const fetchSnippets = async () => {
    try {
      const queryParams = new URLSearchParams({
        page,
        per_page: 10,
        search: searchQuery || '',
        time_filter: timeFilter || 'all',
        categories: selectedCategories.length > 0 ? selectedCategories.join(',') : null
      });

      const response = await fetch(`${API_BASE_URL}/snippets?${queryParams.toString()}`);
      if (!response.ok) {
        throw new Error('Failed to fetch snippets');
      }
      const data = await response.json();
      // Ensure that data.posts is an array before setting it to state
      setAllSnippets(Array.isArray(data.posts) ? data.posts : []);
      setTotalPages(Math.ceil(data.total / data.per_page));
    } catch (error) {
      console.error('Error fetching snippets:', error);
      setAllSnippets([]); // Set to empty array in case of error
    }
  };

  useEffect(() => {
    fetchSnippets();
  }, [page, searchQuery, timeFilter, selectedCategories]);

  const filteredSnippets = allSnippets.filter(snippet => {
    const searchLower = searchQuery.toLowerCase();
    return (
      (snippet.snippet_title && snippet.snippet_title.toLowerCase().includes(searchLower)) ||
      (snippet.snippet && snippet.snippet.toLowerCase().includes(searchLower))
    );
  });

  const timeFilteredSnippets = filterSnippets(filteredSnippets);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Box>
      <IconButton
        icon={<HamburgerIcon />}
        onClick={toggleMobileMenu}
        display={{ base: 'block', md: 'none' }}
        position="absolute"
        top="1rem"
        right="1rem"
        zIndex="1000"
      />

      {/* Mobile menu */}
      <VStack
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100vh"
        bg="white"
        zIndex="999"
        transform={isMobileMenuOpen ? 'translateX(0)' : 'translateX(-100%)'}
        transition="transform 0.3s ease-in-out"
        p={4}
        spacing={4}
        display={{ base: 'flex', md: 'none' }}
      >
        {/* Add your categories here */}
        <Button onClick={() => {/* Handle category selection */}}>Category 1</Button>
        <Button onClick={() => {/* Handle category selection */}}>Category 2</Button>
        {/* ... more categories ... */}
      </VStack>

      <Box 
        display={{ base: isMobileMenuOpen ? 'none' : 'block', md: 'block' }}
        maxWidth={{ base: "100%", md: "800px" }}
        margin="auto"
        padding={{ base: 0, md: 4 }}
        width="100%"
      >
        {timeFilteredSnippets.map(snippet => (
          <Box 
            key={snippet.id} 
            mb={4} 
            boxShadow="lg" 
            borderRadius="md" 
            transition="all 0.3s"
            _hover={{
              transform: "translateY(-5px)",
              boxShadow: "xl"
            }}
          >
            <SnippetCard 
              key={snippet.id} 
              snippet={snippet} 
              onOpenLoginModal={onOpenLoginModal}
              refreshSnippets={fetchSnippets}
            />
          </Box>
        ))}
        
        {/* Pagination */}
        <HStack spacing={4} justify="center" mt={4}>
          <Button 
            onClick={() => setPage(prev => Math.max(prev - 1, 1))} 
            disabled={page === 1}
          >
            Previous
          </Button>
          <Text>Page {page} of {totalPages}</Text>
          <Button 
            onClick={() => setPage(prev => Math.min(prev + 1, totalPages))} 
            disabled={page === totalPages}
          >
            Next
          </Button>
        </HStack>
      </Box>
    </Box>
  );
}

export default SnippetList;