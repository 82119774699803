import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, VStack, Heading, Text, Spinner } from '@chakra-ui/react';
import SnippetCard from './SnippetCard';
import { API_BASE_URL } from '../config';

function VideoSnippetsPage() {
  const [snippets, setSnippets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { videoTitle } = useParams();

  useEffect(() => {
    const fetchSnippets = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/video_page?video_title=${encodeURIComponent(videoTitle)}`);
        if (!response.ok) {
          throw new Error('Failed to fetch snippets');
        }
        const data = await response.json();
        setSnippets(data.posts);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSnippets();
  }, [videoTitle]);

  if (loading) return <Spinner />;
  if (error) return <Text color="red.500">{error}</Text>;

  return (
    <Box p={5} maxWidth={{ base: "95%", md: "800px" }} margin="auto" width="100%">
      <Heading mb={4}>Snippets from: {videoTitle}</Heading>
      <VStack spacing={4} align="stretch">
        {snippets.map(snippet => (
          <SnippetCard key={snippet.id} snippet={snippet} />
        ))}
      </VStack>
    </Box>
  );
}

export default VideoSnippetsPage;
