import React, { useState, useContext, useEffect } from 'react';
import { Box, VStack, HStack, Text, Tag, Button, Icon, Image, Spacer, useToast } from '@chakra-ui/react';
import { FaPlay, FaHeart, FaComment, FaShare } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Assume you have an AuthContext
import { Link as RouterLink } from 'react-router-dom';
import { API_BASE_URL } from '../config';

function getCategoryColor(category) {
  switch (category.toLowerCase()) {
    case 'technology':
      return 'blue';
    case 'science':
      return 'green';
    case 'politics':
      return 'red';
    case 'entertainment':
      return 'purple';
    case 'sports':
      return 'orange';
    default:
      return 'gray';
  }
}

function SnippetCard({ snippet, onOpenLoginModal }) {
  const [likes, setLikes] = useState(snippet.likes || 0);
  const [commentsCount, setCommentsCount] = useState(snippet.comments ? snippet.comments.length : 0);
  const { isAuthenticated, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchSnippetData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/post/${snippet.id}`); // Changed to port 8000
        if (response.ok) {
          const data = await response.json();
          setLikes(data.likes || 0);
          setCommentsCount(data.comments ? data.comments.length : 0);
        }
      } catch (error) {
        console.error('Error fetching snippet data:', error);
      }
    };

    fetchSnippetData();
  }, [snippet.id]);

  const handleLike = async () => {
    if (!isAuthenticated) {
      onOpenLoginModal();
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/like/${snippet.id}`, { // Changed to port 8000
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.message === 'Post liked successfully') {
          setLikes(prevLikes => prevLikes + 1);
        } else if (data.message === 'Post unliked successfully') {
          setLikes(prevLikes => prevLikes - 1);
        }
      }
    } catch (error) {
      console.error('Error liking/unliking snippet:', error);
    }
  };

  const handleComment = () => {
    if (!isAuthenticated) {
      onOpenLoginModal();
    } else {
      navigate(`/snippet/${snippet.id}`);
    }
  };

  const handleCardClick = () => {
    navigate(`/snippet/${snippet.id}`);
  };

  const formattedDate = snippet.upload_date
    ? format(parseISO(snippet.upload_date), 'MMMM d, yyyy')
    : 'Date not available';

  const handleShare = () => {
    // Implement share functionality here
    // For now, let's just copy the snippet URL to clipboard
    const snippetUrl = `${window.location.origin}/snippet/${snippet.id}`;
    navigator.clipboard.writeText(snippetUrl).then(() => {
      toast({
        title: "Link copied!",
        description: "The snippet link has been copied to your clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }).catch(err => {
      console.error('Failed to copy: ', err);
      toast({
        title: "Failed to copy link",
        description: "An error occurred while trying to copy the link.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  return (
    <Box 
      borderWidth={1} 
      borderRadius="lg" 
      overflow="hidden" 
      p={4} 
      position="relative"
      onClick={handleCardClick}
      cursor="pointer"
      width="100%"
    >
      <Image
        src={snippet.thumbnail}
        alt={snippet.video_title}
        position="absolute"
        right="4"
        top="4"
        maxHeight={{ base: "100px", md: "200px" }}
        width="auto"
        maxWidth={{ base: "80px", md: "100px" }}
        objectFit="contain"
      />
      <VStack align="stretch" spacing={3} pr={{ base: '0', md: '120px' }}>
        <Tag size="md" variant="solid" colorScheme={getCategoryColor(snippet.main_category)} alignSelf="flex-start">
          {snippet.main_category}
        </Tag>
        <Text fontSize="2xl" fontWeight="bold">{snippet.snippet_title}</Text>
        <Text noOfLines={3}>{snippet.snippet}</Text>
        <Spacer />
        <HStack>
          {snippet.tags.map((tag, index) => (
            <Tag key={index} size="sm">{tag}</Tag>
          ))}
        </HStack>
        <Text fontSize="sm" color="blue.500" fontWeight="medium">
          More from: <Text 
            as={RouterLink} 
            to={`/video_page/${encodeURIComponent(snippet.video_title)}`}
            fontWeight="medium"
            color="blue.600"
            _hover={{ color: "blue.700", textDecoration: "underline" }}
            onClick={(e) => e.stopPropagation()}
          >
            {snippet.video_title} - {snippet.channel_name}
          </Text>
        </Text>
        <HStack mt={2} spacing={4} wrap="wrap">
          <Button 
            leftIcon={<Icon as={FaHeart} />} 
            colorScheme="pink" 
            variant="outline" 
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              handleLike();
            }}
          >
            Like ({likes})
          </Button>
          <Button 
            leftIcon={<Icon as={FaComment} />} 
            colorScheme="blue" 
            variant="outline" 
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              handleComment();
            }}
          >
            Comment ({commentsCount})
          </Button>
          <Button 
            leftIcon={<Icon as={FaShare} />} 
            colorScheme="green" 
            variant="outline" 
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              handleShare();
            }}
          >
            Share
          </Button>
          <Button 
            leftIcon={<Icon as={FaPlay} />}
            colorScheme="red" 
            size="sm"
            variant="outline" 
            as="a"
            href={snippet.timestamp_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Watch
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
}

export default SnippetCard;
