import React, { useState, useCallback, useContext } from 'react';
import { ChakraProvider, Box, Container, Flex, extendTheme, useDisclosure } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { mode } from '@chakra-ui/theme-tools'; // Import mode function
import LoginSignupModal from './components/LoginSignupModal';
import HomePage from './pages/HomePage';
import SnippetPage from './pages/SnippetPage';
import { AuthContext } from './context/AuthContext';
import NavBar from './components/NavBar';
import VideoSnippetsPage from './components/VideoSnippetsPage';
import ChannelPage from './components/ChannelPage';
import SubmitRequestButton from './components/SubmitRequestButton';

const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: mode('gray.50', 'gray.900')(props),
      },
    }),
  },
});

function App() {
  const [searchQuery, setSearchQuery] = useState('');
  const [timeFilter, setTimeFilter] = useState('all');
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuthenticated, login, logout } = useContext(AuthContext);

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category) 
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const filterSnippets = useCallback((snippets) => {
    return snippets.filter(snippet => {
      if (!snippet.upload_date) return true;
      const uploadDate = new Date(snippet.upload_date);
      const now = new Date();
      const timeDiff = now - uploadDate;
      const daysDiff = timeDiff / (1000 * 3600 * 24);

      const timeFilterPass = timeFilter === 'all' || 
        (timeFilter === 'day' && daysDiff <= 1) ||
        (timeFilter === 'week' && daysDiff <= 7) ||
        (timeFilter === 'month' && daysDiff <= 30);

      const categoryFilterPass = selectedCategories.length === 0 || 
        selectedCategories.includes(snippet.main_category);

      return timeFilterPass && categoryFilterPass;
    });
  }, [timeFilter, selectedCategories]);

  const handleLogin = (token) => {
    login(token);
    localStorage.setItem('token', token);
  };

  const handleLogout = () => {
    logout();
    localStorage.removeItem('token');
  };

  const handleOpenLoginModal = () => {
    if (!isAuthenticated) {
      onOpen();
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Flex direction="column" minHeight="100vh">
          <NavBar 
            isAuthenticated={isAuthenticated}
            onOpenLoginModal={handleOpenLoginModal}
            onLogout={handleLogout}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            selectedCategories={selectedCategories}
            handleCategoryChange={handleCategoryChange}
          />
          <Box as="main" flex={1} mt="80px" pt={8}>
            <Container maxW="80%">
              <Routes>
                <Route path="/" element={
                  <HomePage 
                    searchQuery={searchQuery}
                    timeFilter={timeFilter}
                    categories={categories}
                    selectedCategories={selectedCategories}
                    handleCategoryChange={handleCategoryChange}
                    filterSnippets={filterSnippets}
                    setCategories={setCategories}
                    onOpenLoginModal={handleOpenLoginModal}
                  />
                } />
                <Route path="/snippet/:id" element={<SnippetPage onOpenLoginModal={handleOpenLoginModal} />} />
                <Route path="/video_page/:videoTitle" element={<VideoSnippetsPage />} />
                <Route path="/channel/:channelName" element={<ChannelPage />} />
              </Routes>
            </Container>
          </Box>
          <SubmitRequestButton />
        </Flex>
        <LoginSignupModal isOpen={isOpen} onClose={onClose} onLogin={handleLogin} />
      </Router>
    </ChakraProvider>
  );
}

export default App;