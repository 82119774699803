import React, { useState } from 'react';
import { Box, IconButton, Tooltip, useToast, Popover, PopoverTrigger, PopoverContent, PopoverBody, Input, Button, VStack } from '@chakra-ui/react';
import { FaEnvelope } from 'react-icons/fa';
import { API_BASE_URL } from '../config';

function SubmitRequestButton() {
  const [isOpen, setIsOpen] = useState(false);
  const [request, setRequest] = useState('');
  const toast = useToast();

  const handleSubmit = async () => {
    if (!request.trim()) {
      toast({
        title: "Error",
        description: "Please enter a request",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/submit_request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: request }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit request');
      }

      toast({
        title: 'Request submitted',
        description: 'Your request has been sent successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setRequest('');
      setIsOpen(false);
    } catch (error) {
      console.error('Error submitting request:', error);
      toast({
        title: 'Error',
        description: 'Failed to submit request. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box position="fixed" bottom="4" right="4" zIndex="sticky">
      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        placement="top-end"
      >
        <PopoverTrigger>
          <Tooltip label="Submit request" placement="left">
            <IconButton
              icon={<FaEnvelope />}
              onClick={() => setIsOpen(!isOpen)}
              isRound
              size="lg"
              colorScheme="blue"
              aria-label="Submit request"
            />
          </Tooltip>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody>
            <VStack spacing={3}>
              <Input
                placeholder="Enter your request"
                value={request}
                onChange={(e) => setRequest(e.target.value)}
              />
              <Button colorScheme="blue" onClick={handleSubmit} width="100%">
                Submit
              </Button>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}

export default SubmitRequestButton;
